<template>
  <!-- 我的客户 -->
  <div class="customer_box">
 <div style="margin-bottom:20px;">
     <div class="customer_search_box" style="position: static;">
      <div class="search-item">
        <span>关键字</span>
        <el-input style="width: 190px;" clearable placeholder="客户名称、联系人、联系电话" size="small" v-model="filterinfo.search"></el-input>
      </div>
      <div class="search-item">
        <span>企业类型</span>
        <el-select style="width: 104px;" clearable @clear="filterinfo.shopType=undefined" size="small" v-model="filterinfo.shopType" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <span>客户属性</span>
        <el-select style="width: 104px;" clearable @clear="filterinfo.attr=undefined" size="small" v-model="filterinfo.attr" placeholder="请选择">
          <el-option
            v-for="item in attributeOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <span>状态</span>
        <el-select style="width: 104px;" clearable @clear="filterinfo.status=''" size="small" v-model="filterinfo.status" placeholder="请选择">
          <el-option
            v-for="item in stateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="btn_box">
        <el-button class="btn_item" type="primary" size="small" @click="getCustomerList(1)">查询</el-button>
        <el-button class="btn_item" size="small" @click="resetClick">重置</el-button>
      </div>
    </div>
 </div>
    <div class="table_mode">
      <el-table :data="tableData" v-loading="loading" @sort-change="handleSortChange" stripe style="width: 100%">
        <el-table-column prop="companyName" label="客户企业名称" min-width="200" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="attr" label="客户属性" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="shopType" label="企业类型" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="contacter" label="联系人" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Mobile" label="联系电话" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="amount" label="本月下单金额" sortable="custom" min-width="130" :show-overflow-tooltip="true" align="center">
          <template slot-scope="scope">
            <span style="color: #F92B2B;">￥{{Number(scope.row.amount).toFixed(2)}}</span>
          </template>
        </el-table-column>
         <el-table-column prop="statusTitle" label="状态" min-width="80" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="address" label="操作 " width="130" align="center">
          <template slot-scope="scope">
            <div class="table_btn">
              <div class="btn_item" v-if="scope.row.statusTitle=='正常'" @click="dkxdClick(scope.row.memberId)">代客下单</div>
              <div class="btn_item" v-if="scope.row.statusTitle=='拉黑'" style="color:#999" @click="dkxdClick('black')">代客下单</div>
            </div>
          </template>
        </el-table-column>
        <div slot="empty" class="nodata_box">
          <img src="@/assets/images/index/nodata.png"/>
          <span class="txt">暂无数据哦~</span>
        </div>
      </el-table>
    </div>
    <div class="pagination_box">
      <el-pagination
        background
        layout="prev, pager, next, sizes, total"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="filterinfo.pageSize"
        :current-page.sync="filterinfo.page"
        :total="total"
        @current-change="getCustomerList"
        @size-change="getCustomerList(1)">
      </el-pagination>
    </div>
     <el-dialog :visible="addressVisible" :lock-scroll="false" center custom-class="self-dialog" @close="onClose">
            <span class="dialog-body">
                <div>
                    <p style="font-family: Source Han Sans CN, Source Han Sans CN;font-weight: bold;font-size: 22px;color: #1A1A1A;">完善客户信息</p>
                    <p style="font-weight: 500;font-size: 12px;color: #F92B2B;margin:10px 0;">请先完善客户的信息后再进行代客下单操作</p>
                </div>
                <div>
                   <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
  <el-form-item label="联系人" prop="contacter">
    <el-input v-model="ruleForm.contacter" placeholder="请输入"></el-input>
  </el-form-item>
  <el-form-item label="联系电话" prop="mobile">
    <el-input v-model="ruleForm.mobile" placeholder="请输入"></el-input>
  </el-form-item>
  <el-form-item label="企业地址" prop="provinceId" >
  <el-select style="margin-right:10px;" v-model="ruleForm.provinceId" @change="provinceChange" placeholder="请选择省">
    <el-option
      v-for="item in provinceOptions"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
 
  </el-form-item>
  <el-form-item  prop="cityId" >
 <el-select style="margin-right:10px;" v-model="ruleForm.cityId"  @change="cityChange" placeholder="请选择市">
    <el-option
      v-for="item in cityOptions"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>

 
  </el-form-item>
  <el-form-item  prop="areaId" >
   <el-select v-model="ruleForm.areaId"   placeholder="请选择区">
    <el-option
      v-for="item in areaOptions"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
 
  </el-form-item>
 
  <el-form-item label="详细地址" prop="address">
    <el-input type="textarea" placeholder="请输入"  v-model="ruleForm.address"></el-input>
  </el-form-item>
</el-form>
                </div>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button style="width:200px;" @click="addressVisible=false">取消</el-button>
                <el-button style="background:#1A9DFF;color:#fff;width:200px;" @click="submitForm">确定</el-button>
            </span>
        </el-dialog>
  </div>
</template>

<script>
import { handlerTableData } from '@/utils/common'
export default {
  name: 'MyCustomer',
  data() {
    return {
      loading: false,
      total: 0,
      provinceOptions:[],
      cityOptions:[],
      areaOptions:[],
      filterinfo:{
        orderBy:'',
        page:1,
        pageSize:10,
        search: '',
        shopType: undefined,
        attr: undefined,
        status:''
      },
      tableData:[],
      attributeOptions: [],
      stateOptions: [
        // {
        //   label: '全部',
        //   value: 0
        // },
        {
          label: '正常',
          value: '1'
        },
        {
          label: '拉黑',
          value: '2'
        },
 
      ],
      options: [],
      roleNumber:localStorage.getItem('roleNumber'),
      addressVisible:false,
        ruleForm: {
          contacter: '',
          mobile: '',
          provinceId: '',
          cityId: '',
          areaId: '',
          memberId: '',
         
        },
        rules: {
          contacter: [
            { required: true, message: '请输入联系人', trigger: 'blur' },
            { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
          ],
          mobile: [
            { required: true, message: '请输入联系电话', trigger: 'blur' },
            { min: 11, max: 11, message: '长度在11个字符', trigger: 'blur' }
          ],
          provinceId: [
            { required: true, message: '请选择省', trigger: 'blur' }

          ],
          cityId: [
            { required: true, message: '请选择市', trigger: 'blur' }

          ],
          areaId: [
            { required: true, message: '请选择区', trigger: 'blur' }

          ],
          address: [
            { required: true, message: '请输入详细地址', trigger: 'blur' }
           
          ],
         
        }
    };
  },
  created() {

  },
  mounted(){
    this.getCustomerList()
    this.getAddress(0,'provinceOptions')
    console.log(this.roleNumber);
    if(this.roleNumber == 3){
      this.dkxdClick(0,3)
    }
  },
  methods: {
    provinceChange(){
       this.getAddress(this.ruleForm.provinceId,'cityOptions')
       this.ruleForm.cityId=''
       this.ruleForm.areaId=''
       this.cityOptions=[]
       this.areaOptions=[]
    },
    cityChange(){
       this.getAddress(this.ruleForm.cityId,'areaOptions')
       this.ruleForm.areaId=''
       this.areaOptions=[]
    },
    //添加地址
  
       submitForm() {
        this.$refs.ruleForm.validate(async(valid) => {
          if (valid) {
           const res= await this.$api.subAddress(this.ruleForm)
           if(res.data.code==200){
             this.addressVisible=false
            this.$message.success('添加成功')
             this.getCustomerList()
           }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      //清空数据
      resetForm() {
        this.$refs.ruleForm.resetFields();
      },
      //关闭弹窗
    onClose(){
this.addressVisible=false
this.resetForm()
    },
    handleChange(){

    },
    //获取全国地址
   async getAddress(parentId,option){
      const res = await this.$api.getAllAddress({parentId})
      // if(res.data.code!=200) return this.$message.error(res.data.msg)
      this[option] = res.data.data
    },
    // 点击重置
    resetClick(){
      this.filterinfo = {
        orderBy:'',
        page:1,
        pageSize:10,
        search: '',
        shopType: undefined,
        attr: undefined,
        status: '',
      }
      this.getCustomerList()
    },
    // 获取我的客户列表
    getCustomerList(num){
      if(num==1){
        this.filterinfo.page = 1
      }
      let params = this.filterinfo
      this.loading = true
      this.$api.pcCustomerList(params).then((res) => {
        this.loading = false
        if (res.data.code === 200) {
          this.tableData = handlerTableData(res.data.data.list)
          // this.tableData = res.data.data.list
          this.options = res.data.data.shopType
          this.attributeOptions = res.data.data.attr
          this.total = res.data.data.total
        }
      });
    },
    // 代客下单
  async  dkxdClick(memberId,roleNumber){
    if(memberId=='black'){
      this.$message.error('此客户是黑名单客户，禁止下单')
      return
    }
    this.ruleForm.memberId=memberId
      const res = await this.$api.checkAddress({memberId})
      if(res.data.code!=200) return this.addressVisible=true
      const  result = await this.$api.checkReceivable({memberId})
       if(result.data.code!=200) return 
      this.$api.visitorDoUndermember({member_id:memberId,order_number:this.$route.query.orderNumber}).then((res) => {
        if (res.data.code === 200) {
          if(this.$route.query.orderNumber){
            this.$message.success('已将订单商品添加到客户的采购车中')
             setTimeout(() => {
            if(roleNumber == 3){
            this.$router.push('/empty?roleNumber='+roleNumber)
          }else{
            this.$router.push('/empty')
          }
          }, 2000);
          }else{
            if(roleNumber == 3){
            this.$router.push('/empty?roleNumber='+roleNumber)
          }else{
            this.$router.push('/empty')
          }
          }
         
        }
      });
      
    },
    handleSortChange({ column, prop, order }) {
      if(order == 'ascending'){
        this.filterinfo.orderBy = "asc"
      }else{
        this.filterinfo.orderBy = "desc"
      }
      this.getCustomerList()
    }
  }
};
</script>
<style lang="scss" scoped>
.customer_box{
  position: relative;

}
    .el-form-item__label{
  text-align: left !important;
}


</style>
<style lang="scss" >
  .self-dialog  {
  width:500px;
    border-radius: 10px !important;   
.el-form-item__content{
 
   display: flex !important;
}
}

</style>